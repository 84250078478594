import { Pose } from "@tensorflow-models/posenet";

export interface Position {
  image: string;
  pose: Pose;
  points: number;
}

export const positions: Position[] = [
  {
    image: "wall1",
    points: 100,
    pose: {
      "score": 0.9854452574954313,
      "keypoints": [
        {
          "score": 0.9991105198860168,
          "part": "nose",
          "position": {
            "x": 778.494343547078,
            "y": 290.23577008928567
          }
        },
        {
          "score": 0.9977815747261047,
          "part": "leftEye",
          "position": {
            "x": 759.1309354707791,
            "y": 276.96283989448045
          }
        },
        {
          "score": 0.9985625147819519,
          "part": "rightEye",
          "position": {
            "x": 793.3759385146104,
            "y": 277.4514508928571
          }
        },
        {
          "score": 0.8821969628334045,
          "part": "leftEar",
          "position": {
            "x": 734.2063717532467,
            "y": 274.9760551948052
          }
        },
        {
          "score": 0.9490633606910706,
          "part": "rightEar",
          "position": {
            "x": 820.407822646104,
            "y": 275.74797077922074
          }
        },
        {
          "score": 0.9994649291038513,
          "part": "leftShoulder",
          "position": {
            "x": 706.7371651785716,
            "y": 347.8826603084416
          }
        },
        {
          "score": 0.9996004700660706,
          "part": "rightShoulder",
          "position": {
            "x": 857.6575436282467,
            "y": 359.7519277597403
          }
        },
        {
          "score": 0.999594509601593,
          "part": "leftElbow",
          "position": {
            "x": 604.2049512987014,
            "y": 376.80993810876623
          }
        },
        {
          "score": 0.9972221851348877,
          "part": "rightElbow",
          "position": {
            "x": 953.9537844967533,
            "y": 387.114448051948
          }
        },
        {
          "score": 0.9875205755233765,
          "part": "leftWrist",
          "position": {
            "x": 499.8423295454545,
            "y": 377.1029829545454
          }
        },
        {
          "score": 0.9741739630699158,
          "part": "rightWrist",
          "position": {
            "x": 1053.0472301136365,
            "y": 402.79657061688306
          }
        },
        {
          "score": 0.9943650960922241,
          "part": "leftHip",
          "position": {
            "x": 727.087002840909,
            "y": 585.6911779626623
          }
        },
        {
          "score": 0.9983699917793274,
          "part": "rightHip",
          "position": {
            "x": 823.9356229707791,
            "y": 587.6863331980519
          }
        },
        {
          "score": 0.9969834685325623,
          "part": "leftKnee",
          "position": {
            "x": 706.7557325487014,
            "y": 722.2094409496754
          }
        },
        {
          "score": 0.9984298944473267,
          "part": "rightKnee",
          "position": {
            "x": 847.0141791801948,
            "y": 728.6140422077922
          }
        },
        {
          "score": 0.9900534749031067,
          "part": "leftAnkle",
          "position": {
            "x": 680.1744115259742,
            "y": 863.4828530844156
          }
        },
        {
          "score": 0.9900758862495422,
          "part": "rightAnkle",
          "position": {
            "x": 863.8374340503246,
            "y": 865.1304788961038
          }
        }
      ]
    }
  },
  {
    image: "wall2",
    points: 100,
    pose: {
      "score": 0.9881436719613916,
      "keypoints": [
        {
          "score": 0.9995219707489014,
          "part": "nose",
          "position": {
            "x": 811.6293374594156,
            "y": 298.0120738636364
          }
        },
        {
          "score": 0.9994431138038635,
          "part": "leftEye",
          "position": {
            "x": 793.1047331574675,
            "y": 278.02232142857144
          }
        },
        {
          "score": 0.9979907274246216,
          "part": "rightEye",
          "position": {
            "x": 829.6769734172078,
            "y": 280.6695413961039
          }
        },
        {
          "score": 0.9839041233062744,
          "part": "leftEar",
          "position": {
            "x": 765.704012784091,
            "y": 288.4304738230519
          }
        },
        {
          "score": 0.8653244972229004,
          "part": "rightEar",
          "position": {
            "x": 845.4144176136365,
            "y": 289.21296672077915
          }
        },
        {
          "score": 0.999055802822113,
          "part": "leftShoulder",
          "position": {
            "x": 728.3734019886365,
            "y": 397.14189326298697
          }
        },
        {
          "score": 0.9979912042617798,
          "part": "rightShoulder",
          "position": {
            "x": 882.7107092126623,
            "y": 395.358588676948
          }
        },
        {
          "score": 0.9963766932487488,
          "part": "leftElbow",
          "position": {
            "x": 704.0012936282467,
            "y": 519.2331574675325
          }
        },
        {
          "score": 0.9970911145210266,
          "part": "rightElbow",
          "position": {
            "x": 910.6193689123377,
            "y": 523.2217938311688
          }
        },
        {
          "score": 0.9919765591621399,
          "part": "leftWrist",
          "position": {
            "x": 686.6781655844156,
            "y": 624.3564326298701
          }
        },
        {
          "score": 0.9944993257522583,
          "part": "rightWrist",
          "position": {
            "x": 912.829494724026,
            "y": 622.6617035308441
          }
        },
        {
          "score": 0.9990045428276062,
          "part": "leftHip",
          "position": {
            "x": 762.3480367288962,
            "y": 617.8793120941559
          }
        },
        {
          "score": 0.9982026219367981,
          "part": "rightHip",
          "position": {
            "x": 851.1843039772727,
            "y": 621.1359831574675
          }
        },
        {
          "score": 0.997334361076355,
          "part": "leftKnee",
          "position": {
            "x": 769.6514052353896,
            "y": 772.5752587256493
          }
        },
        {
          "score": 0.9953248500823975,
          "part": "rightKnee",
          "position": {
            "x": 834.7851308847403,
            "y": 773.5517958603896
          }
        },
        {
          "score": 0.9921329617500305,
          "part": "leftAnkle",
          "position": {
            "x": 802.0845677759742,
            "y": 898.2866274350649
          }
        },
        {
          "score": 0.9932679533958435,
          "part": "rightAnkle",
          "position": {
            "x": 816.989371956169,
            "y": 898.4990868506493
          }
        }
      ]
    }
  },
  {
    image: "wall3",
    points: 200,
    pose: {
      "score": 0.92377949812833,
      "keypoints": [
        {
          "score": 0.9950146079063416,
          "part": "nose",
          "position": {
            "x": 834.4640827922078,
            "y": 406.0727982954545
          }
        },
        {
          "score": 0.9905882477760315,
          "part": "leftEye",
          "position": {
            "x": 810.0880174512988,
            "y": 399.00799005681813
          }
        },
        {
          "score": 0.9259955286979675,
          "part": "rightEye",
          "position": {
            "x": 832.4375,
            "y": 388.93663758116884
          }
        },
        {
          "score": 0.9632973074913025,
          "part": "leftEar",
          "position": {
            "x": 757.1839488636365,
            "y": 392.1611962256493
          }
        },
        {
          "score": 0.44504475593566895,
          "part": "rightEar",
          "position": {
            "x": 830.2603997564936,
            "y": 391.06792816558436
          }
        },
        {
          "score": 0.9970322847366333,
          "part": "leftShoulder",
          "position": {
            "x": 756.3284801136365,
            "y": 485.1062550730519
          }
        },
        {
          "score": 0.9783339500427246,
          "part": "rightShoulder",
          "position": {
            "x": 796.6323051948052,
            "y": 426.9904626623377
          }
        },
        {
          "score": 0.9869604110717773,
          "part": "leftElbow",
          "position": {
            "x": 884.8295961850649,
            "y": 474.49462256493507
          }
        },
        {
          "score": 0.7947705984115601,
          "part": "rightElbow",
          "position": {
            "x": 908.8464133522727,
            "y": 400.7228845373377
          }
        },
        {
          "score": 0.9403596520423889,
          "part": "leftWrist",
          "position": {
            "x": 991.2410967938312,
            "y": 347.1415635146104
          }
        },
        {
          "score": 0.9590428471565247,
          "part": "rightWrist",
          "position": {
            "x": 1007.0089793019481,
            "y": 342.5927100243506
          }
        },
        {
          "score": 0.9963361024856567,
          "part": "leftHip",
          "position": {
            "x": 699.8997311282467,
            "y": 708.7700131899351
          }
        },
        {
          "score": 0.9855374097824097,
          "part": "rightHip",
          "position": {
            "x": 752.6000913149351,
            "y": 676.3397422889609
          }
        },
        {
          "score": 0.9988844990730286,
          "part": "leftKnee",
          "position": {
            "x": 839.6274857954545,
            "y": 860.4822443181818
          }
        },
        {
          "score": 0.9441952109336853,
          "part": "rightKnee",
          "position": {
            "x": 843.8498883928571,
            "y": 818.275187702922
          }
        },
        {
          "score": 0.9534959197044373,
          "part": "leftAnkle",
          "position": {
            "x": 748.9875202922078,
            "y": 966.7198660714284
          }
        },
        {
          "score": 0.8493621349334717,
          "part": "rightAnkle",
          "position": {
            "x": 755.1543983360391,
            "y": 883.0597098214284
          }
        }
      ]
    }
  },
  {
    image: "wall4",
    points: 150,
    pose: {
      "score": 0.9570328593254089,
      "keypoints": [
        {
          "score": 0.9636709094047546,
          "part": "nose",
          "position": {
            "x": 795.2577871347403,
            "y": 648.6875253652597
          }
        },
        {
          "score": 0.9431334137916565,
          "part": "leftEye",
          "position": {
            "x": 782.1068384740261,
            "y": 628.4891943993507
          }
        },
        {
          "score": 0.9711742401123047,
          "part": "rightEye",
          "position": {
            "x": 814.790127840909,
            "y": 633.8970170454545
          }
        },
        {
          "score": 0.8668376207351685,
          "part": "leftEar",
          "position": {
            "x": 763.098721590909,
            "y": 603.0898691152597
          }
        },
        {
          "score": 0.9435825943946838,
          "part": "rightEar",
          "position": {
            "x": 846.270799512987,
            "y": 606.1818689123377
          }
        },
        {
          "score": 0.9831321239471436,
          "part": "leftShoulder",
          "position": {
            "x": 725.2167207792209,
            "y": 627.5707183441559
          }
        },
        {
          "score": 0.994361400604248,
          "part": "rightShoulder",
          "position": {
            "x": 894.411424512987,
            "y": 601.7365817775974
          }
        },
        {
          "score": 0.9733394384384155,
          "part": "leftElbow",
          "position": {
            "x": 669.3901176948052,
            "y": 699.0706168831168
          }
        },
        {
          "score": 0.9899849891662598,
          "part": "rightElbow",
          "position": {
            "x": 928.898336038961,
            "y": 656.8441558441559
          }
        },
        {
          "score": 0.9428280591964722,
          "part": "leftWrist",
          "position": {
            "x": 662.5154728084417,
            "y": 853.7479200487012
          }
        },
        {
          "score": 0.9723454713821411,
          "part": "rightWrist",
          "position": {
            "x": 928.0433999594156,
            "y": 788.5027140827922
          }
        },
        {
          "score": 0.9874123334884644,
          "part": "leftHip",
          "position": {
            "x": 747.656452922078,
            "y": 695.7421114042209
          }
        },
        {
          "score": 0.9813845157623291,
          "part": "rightHip",
          "position": {
            "x": 872.1260146103896,
            "y": 690.4802404626623
          }
        },
        {
          "score": 0.9178522825241089,
          "part": "leftKnee",
          "position": {
            "x": 705.1711140422078,
            "y": 787.9074928977273
          }
        },
        {
          "score": 0.9827165603637695,
          "part": "rightKnee",
          "position": {
            "x": 905.4855671672078,
            "y": 784.4165229301948
          }
        },
        {
          "score": 0.9153682589530945,
          "part": "leftAnkle",
          "position": {
            "x": 675.5742694805194,
            "y": 896.8621144480519
          }
        },
        {
          "score": 0.9404343962669373,
          "part": "rightAnkle",
          "position": {
            "x": 928.0170708198052,
            "y": 913.3089995941559
          }
        }
      ]
    }
  },
  {
    image: "wall5",
    points: 300,
    pose: {
      "score": 0.7851711090873269,
      "keypoints": [
        {
          "score": 0.8946778774261475,
          "part": "nose",
          "position": {
            "x": 869.0353845373377,
            "y": 662.2902546672078
          }
        },
        {
          "score": 0.9255375862121582,
          "part": "leftEye",
          "position": {
            "x": 854.508015422078,
            "y": 639.4424969561687
          }
        },
        {
          "score": 0.8534103035926819,
          "part": "rightEye",
          "position": {
            "x": 869.6199523133116,
            "y": 655.2100750811687
          }
        },
        {
          "score": 0.9151043891906738,
          "part": "leftEar",
          "position": {
            "x": 821.6519125405844,
            "y": 662.0317573051948
          }
        },
        {
          "score": 0.6742181777954102,
          "part": "rightEar",
          "position": {
            "x": 876.1872463474026,
            "y": 650.6049107142858
          }
        },
        {
          "score": 0.9080116152763367,
          "part": "leftShoulder",
          "position": {
            "x": 858.882609577922,
            "y": 743.2603490259739
          }
        },
        {
          "score": 0.9584702253341675,
          "part": "rightShoulder",
          "position": {
            "x": 909.445895900974,
            "y": 683.7765827922078
          }
        },
        {
          "score": 0.8252295255661011,
          "part": "leftElbow",
          "position": {
            "x": 960.5202414772727,
            "y": 785.9206321022727
          }
        },
        {
          "score": 0.9736732840538025,
          "part": "rightElbow",
          "position": {
            "x": 1002.4470373376623,
            "y": 699.0343952922078
          }
        },
        {
          "score": 0.7138338088989258,
          "part": "leftWrist",
          "position": {
            "x": 995.4641842532468,
            "y": 729.9293577516235
          }
        },
        {
          "score": 0.9484146237373352,
          "part": "rightWrist",
          "position": {
            "x": 1076.5065696022727,
            "y": 705.9817116477273
          }
        },
        {
          "score": 0.9710350632667542,
          "part": "leftHip",
          "position": {
            "x": 969.3141741071429,
            "y": 795.1894023944806
          }
        },
        {
          "score": 0.9092028737068176,
          "part": "rightHip",
          "position": {
            "x": 971.506645698052,
            "y": 768.8238129058441
          }
        },
        {
          "score": 0.6049564480781555,
          "part": "leftKnee",
          "position": {
            "x": 980.7581422483767,
            "y": 694.904981737013
          }
        },
        {
          "score": 0.4872371554374695,
          "part": "rightKnee",
          "position": {
            "x": 1017.7234933035714,
            "y": 689.3331726866884
          }
        },
        {
          "score": 0.4520706236362457,
          "part": "leftAnkle",
          "position": {
            "x": 1028.1291345373377,
            "y": 772.7633674918832
          }
        },
        {
          "score": 0.33282527327537537,
          "part": "rightAnkle",
          "position": {
            "x": 1099.5919744318182,
            "y": 716.5048447646104
          }
        }
      ]
    }
  },
  {
    image: "wall6",
    points: 200,
    pose: {
      "score": 0.9423717786284054,
      "keypoints": [
        {
          "score": 0.9805973172187805,
          "part": "nose",
          "position": {
            "x": 976.6933340097403,
            "y": 359.45226258116884
          }
        },
        {
          "score": 0.9620118737220764,
          "part": "leftEye",
          "position": {
            "x": 965.3453987418832,
            "y": 335.58337560876623
          }
        },
        {
          "score": 0.9558566808700562,
          "part": "rightEye",
          "position": {
            "x": 989.9860491071429,
            "y": 344.6269784902597
          }
        },
        {
          "score": 0.8528849482536316,
          "part": "leftEar",
          "position": {
            "x": 938.1251775568182,
            "y": 335.3241933847403
          }
        },
        {
          "score": 0.5361932516098022,
          "part": "rightEar",
          "position": {
            "x": 1006.113484172078,
            "y": 344.57180905032465
          }
        },
        {
          "score": 0.9876540303230286,
          "part": "leftShoulder",
          "position": {
            "x": 907.822164163961,
            "y": 387.1305803571429
          }
        },
        {
          "score": 0.9923242330551147,
          "part": "rightShoulder",
          "position": {
            "x": 983.4266690340909,
            "y": 407.3264001623377
          }
        },
        {
          "score": 0.8934405446052551,
          "part": "leftElbow",
          "position": {
            "x": 810.1882355925325,
            "y": 364.7517248376623
          }
        },
        {
          "score": 0.9548207521438599,
          "part": "rightElbow",
          "position": {
            "x": 844.448533887987,
            "y": 385.3023792613636
          }
        },
        {
          "score": 0.9703182578086853,
          "part": "leftWrist",
          "position": {
            "x": 726.3842583198052,
            "y": 302.24992390422074
          }
        },
        {
          "score": 0.9605722427368164,
          "part": "rightWrist",
          "position": {
            "x": 724.4182477678571,
            "y": 304.14615462662334
          }
        },
        {
          "score": 0.9943448901176453,
          "part": "leftHip",
          "position": {
            "x": 852.2341213474026,
            "y": 606.0812702922078
          }
        },
        {
          "score": 0.9990800619125366,
          "part": "rightHip",
          "position": {
            "x": 924.4807477678571,
            "y": 626.0272676542209
          }
        },
        {
          "score": 0.9948310852050781,
          "part": "leftKnee",
          "position": {
            "x": 789.762606534091,
            "y": 739.2994115259739
          }
        },
        {
          "score": 0.9992620348930359,
          "part": "rightKnee",
          "position": {
            "x": 848.3785004058441,
            "y": 781.753018465909
          }
        },
        {
          "score": 0.9940913319587708,
          "part": "leftAnkle",
          "position": {
            "x": 831.9816101866884,
            "y": 827.5155742694806
          }
        },
        {
          "score": 0.9920367002487183,
          "part": "rightAnkle",
          "position": {
            "x": 878.5244521103896,
            "y": 927.15234375
          }
        }
      ]
    }
  },
  {
    image: "wall7",
    points: 150,
    pose: {
      "score": 0.9863101664711448,
      "keypoints": [
        {
          "score": 0.9990008473396301,
          "part": "nose",
          "position": {
            "x": 723.8048396915585,
            "y": 381.61150568181813
          }
        },
        {
          "score": 0.9991660118103027,
          "part": "leftEye",
          "position": {
            "x": 703.9534293831168,
            "y": 367.187398538961
          }
        },
        {
          "score": 0.9976907968521118,
          "part": "rightEye",
          "position": {
            "x": 732.9256797889611,
            "y": 359.16454443993507
          }
        },
        {
          "score": 0.905895471572876,
          "part": "leftEar",
          "position": {
            "x": 673.9625608766235,
            "y": 373.3538961038961
          }
        },
        {
          "score": 0.9262092113494873,
          "part": "rightEar",
          "position": {
            "x": 760.6370231331168,
            "y": 351.8814935064935
          }
        },
        {
          "score": 0.9995515942573547,
          "part": "leftShoulder",
          "position": {
            "x": 639.5674208603896,
            "y": 450.5243760146104
          }
        },
        {
          "score": 0.999616265296936,
          "part": "rightShoulder",
          "position": {
            "x": 798.4902597402597,
            "y": 441.0614853896104
          }
        },
        {
          "score": 0.9981297850608826,
          "part": "leftElbow",
          "position": {
            "x": 540.2513189935066,
            "y": 473.2884283685065
          }
        },
        {
          "score": 0.997948944568634,
          "part": "rightElbow",
          "position": {
            "x": 925.8671367694806,
            "y": 478.24682934253246
          }
        },
        {
          "score": 0.9942741990089417,
          "part": "leftWrist",
          "position": {
            "x": 410.2415280032467,
            "y": 491.1595221185065
          }
        },
        {
          "score": 0.9966070652008057,
          "part": "rightWrist",
          "position": {
            "x": 1035.9122869318182,
            "y": 490.51894784902595
          }
        },
        {
          "score": 0.9995318651199341,
          "part": "leftHip",
          "position": {
            "x": 699.702262581169,
            "y": 675.1679433847403
          }
        },
        {
          "score": 0.9996360540390015,
          "part": "rightHip",
          "position": {
            "x": 792.6053926542209,
            "y": 672.1661931818182
          }
        },
        {
          "score": 0.9985010623931885,
          "part": "leftKnee",
          "position": {
            "x": 622.8446124188313,
            "y": 783.346387987013
          }
        },
        {
          "score": 0.9833331108093262,
          "part": "rightKnee",
          "position": {
            "x": 909.0158025568182,
            "y": 795.3782721185064
          }
        },
        {
          "score": 0.9811294674873352,
          "part": "leftAnkle",
          "position": {
            "x": 545.1216010551948,
            "y": 909.3377130681818
          }
        },
        {
          "score": 0.9910510778427124,
          "part": "rightAnkle",
          "position": {
            "x": 841.401963271104,
            "y": 900.4445515422078
          }
        }
      ]
    }
  },
  {
    image: "wall8",
    points: 150,
    pose: {
      "score": 0.9631763731732088,
      "keypoints": [
        {
          "score": 0.9980347752571106,
          "part": "nose",
          "position": {
            "x": 797.1581270292209,
            "y": 597.7138545048701
          }
        },
        {
          "score": 0.9948462247848511,
          "part": "leftEye",
          "position": {
            "x": 786.0165635146104,
            "y": 579.4815340909091
          }
        },
        {
          "score": 0.996309220790863,
          "part": "rightEye",
          "position": {
            "x": 813.2843445616884,
            "y": 584.2262581168832
          }
        },
        {
          "score": 0.9108695983886719,
          "part": "leftEar",
          "position": {
            "x": 760.999619521104,
            "y": 582.3337560876623
          }
        },
        {
          "score": 0.9466949701309204,
          "part": "rightEar",
          "position": {
            "x": 834.0920505275974,
            "y": 587.0443892045455
          }
        },
        {
          "score": 0.9981580376625061,
          "part": "leftShoulder",
          "position": {
            "x": 745.3470982142858,
            "y": 647.7736911525974
          }
        },
        {
          "score": 0.9986558556556702,
          "part": "rightShoulder",
          "position": {
            "x": 859.0608258928571,
            "y": 641.6028561282467
          }
        },
        {
          "score": 0.9693130850791931,
          "part": "leftElbow",
          "position": {
            "x": 708.4321225649351,
            "y": 746.0604454139609
          }
        },
        {
          "score": 0.9881675839424133,
          "part": "rightElbow",
          "position": {
            "x": 895.2424665178571,
            "y": 766.4146205357142
          }
        },
        {
          "score": 0.969968318939209,
          "part": "leftWrist",
          "position": {
            "x": 715.6018668831168,
            "y": 827.9360795454545
          }
        },
        {
          "score": 0.9924794435501099,
          "part": "rightWrist",
          "position": {
            "x": 865.957919034091,
            "y": 856.6162743506493
          }
        },
        {
          "score": 0.9871217012405396,
          "part": "leftHip",
          "position": {
            "x": 771.7461698457791,
            "y": 778.4681158685064
          }
        },
        {
          "score": 0.9847599864006042,
          "part": "rightHip",
          "position": {
            "x": 843.4468090503246,
            "y": 784.013671875
          }
        },
        {
          "score": 0.9436051249504089,
          "part": "leftKnee",
          "position": {
            "x": 759.8385501217533,
            "y": 827.9267197646104
          }
        },
        {
          "score": 0.9494900703430176,
          "part": "rightKnee",
          "position": {
            "x": 847.4877232142858,
            "y": 833.2665888798701
          }
        },
        {
          "score": 0.8656910061836243,
          "part": "leftAnkle",
          "position": {
            "x": 754.5330762987014,
            "y": 884.7572544642858
          }
        },
        {
          "score": 0.8798333406448364,
          "part": "rightAnkle",
          "position": {
            "x": 847.7618963068182,
            "y": 883.6505174512986
          }
        }
      ]
    }
  },
  {
    image: "wall9",
    points: 200,
    pose: {
      "score": 0.9641001119333155,
      "keypoints": [
        {
          "score": 0.9902462959289551,
          "part": "nose",
          "position": {
            "x": 857.6140929383116,
            "y": 290.71935876623377
          }
        },
        {
          "score": 0.9920164346694946,
          "part": "leftEye",
          "position": {
            "x": 831.3445363230519,
            "y": 284.28028612012986
          }
        },
        {
          "score": 0.9901115298271179,
          "part": "rightEye",
          "position": {
            "x": 876.5111099837663,
            "y": 280.0377942370129
          }
        },
        {
          "score": 0.6468271613121033,
          "part": "leftEar",
          "position": {
            "x": 813.2587763798701,
            "y": 275.9001623376623
          }
        },
        {
          "score": 0.8953557014465332,
          "part": "rightEar",
          "position": {
            "x": 885.3587662337663,
            "y": 280.28236607142856
          }
        },
        {
          "score": 0.9971848726272583,
          "part": "leftShoulder",
          "position": {
            "x": 791.1139153814936,
            "y": 335.6411323051948
          }
        },
        {
          "score": 0.9996998906135559,
          "part": "rightShoulder",
          "position": {
            "x": 921.1043780438312,
            "y": 351.2874898538961
          }
        },
        {
          "score": 0.995222270488739,
          "part": "leftElbow",
          "position": {
            "x": 703.6001927759742,
            "y": 267.43077820616884
          }
        },
        {
          "score": 0.9968493580818176,
          "part": "rightElbow",
          "position": {
            "x": 1026.8361150568182,
            "y": 308.6729403409091
          }
        },
        {
          "score": 0.9952427744865417,
          "part": "leftWrist",
          "position": {
            "x": 772.9375253652597,
            "y": 218.21910511363637
          }
        },
        {
          "score": 0.9974731206893921,
          "part": "rightWrist",
          "position": {
            "x": 941.6771002435065,
            "y": 219.2123833198052
          }
        },
        {
          "score": 0.9982026219367981,
          "part": "leftHip",
          "position": {
            "x": 773.2400060876623,
            "y": 556.1720271915584
          }
        },
        {
          "score": 0.9971143007278442,
          "part": "rightHip",
          "position": {
            "x": 858.1571124188313,
            "y": 582.3110795454545
          }
        },
        {
          "score": 0.9931449890136719,
          "part": "leftKnee",
          "position": {
            "x": 625.7231635551948,
            "y": 663.7235947646104
          }
        },
        {
          "score": 0.9963948130607605,
          "part": "rightKnee",
          "position": {
            "x": 843.0215858360391,
            "y": 731.1637834821429
          }
        },
        {
          "score": 0.9448558688163757,
          "part": "leftAnkle",
          "position": {
            "x": 769.8058796672078,
            "y": 708.3258421266235
          }
        },
        {
          "score": 0.9637598991394043,
          "part": "rightAnkle",
          "position": {
            "x": 815.885830965909,
            "y": 825.7030489042209
          }
        }
      ]
    }
  },
  {
    image: "wall10",
    points: 200,
    pose: {
      "score": 0.9468670347157646,
      "keypoints": [
        {
          "score": 0.9991812109947205,
          "part": "nose",
          "position": {
            "x": 860.9085836038962,
            "y": 600.2397017045455
          }
        },
        {
          "score": 0.9982323050498962,
          "part": "leftEye",
          "position": {
            "x": 851.7996144480519,
            "y": 584.3542512175325
          }
        },
        {
          "score": 0.9988610744476318,
          "part": "rightEye",
          "position": {
            "x": 879.470703125,
            "y": 593.5242491883116
          }
        },
        {
          "score": 0.8533384203910828,
          "part": "leftEar",
          "position": {
            "x": 832.0020799512988,
            "y": 585.8951907467533
          }
        },
        {
          "score": 0.9825212359428406,
          "part": "rightEar",
          "position": {
            "x": 912.4385907061688,
            "y": 583.3445363230519
          }
        },
        {
          "score": 0.9957422614097595,
          "part": "leftShoulder",
          "position": {
            "x": 815.5554230925325,
            "y": 643.1849634740258
          }
        },
        {
          "score": 0.9982192516326904,
          "part": "rightShoulder",
          "position": {
            "x": 954.0437297077922,
            "y": 649.8853490259739
          }
        },
        {
          "score": 0.9949976205825806,
          "part": "leftElbow",
          "position": {
            "x": 798.9486607142858,
            "y": 746.3219866071429
          }
        },
        {
          "score": 0.9981424808502197,
          "part": "rightElbow",
          "position": {
            "x": 975.0119977678571,
            "y": 763.2383066152597
          }
        },
        {
          "score": 0.9950563907623291,
          "part": "leftWrist",
          "position": {
            "x": 774.9531503652597,
            "y": 824.1148538961038
          }
        },
        {
          "score": 0.9887017011642456,
          "part": "rightWrist",
          "position": {
            "x": 990.7052303165584,
            "y": 877.8106229707791
          }
        },
        {
          "score": 0.9981642365455627,
          "part": "leftHip",
          "position": {
            "x": 849.0150415990261,
            "y": 807.4252993100649
          }
        },
        {
          "score": 0.9982074499130249,
          "part": "rightHip",
          "position": {
            "x": 946.2029728084416,
            "y": 805.6522676542209
          }
        },
        {
          "score": 0.9702509045600891,
          "part": "leftKnee",
          "position": {
            "x": 802.9914772727273,
            "y": 904.8658685064934
          }
        },
        {
          "score": 0.9782799482345581,
          "part": "rightKnee",
          "position": {
            "x": 914.0910866477273,
            "y": 954.4949269480519
          }
        },
        {
          "score": 0.7636602520942688,
          "part": "leftAnkle",
          "position": {
            "x": 844.7785612824675,
            "y": 906.0584415584415
          }
        },
        {
          "score": 0.5851828455924988,
          "part": "rightAnkle",
          "position": {
            "x": 931.6990411931819,
            "y": 950.5531655844156
          }
        }
      ]
    }
  }
];
