import React from "react";
import ImageComponent from "./ImageComponent";
import VideoSamsung from "../Images/SamsungPromo.mp4";

interface explanationInterface {
  startVideo: boolean;
  startGame: () => void;
}

export default class ExplanationComponent extends React.Component<explanationInterface, any> {

  constructor(props: any) {
    super(props);

    this.state = {
      showImage: false
    };

    setTimeout(() => {
      this.videoEnded();
    }, 1000);
  }

  videoEnded() {
    // this.setState({ showImage: true });
    this.props.startGame();
  }

  render() {
    return (
      <div className="explanation-video-wrapper">
        {this.state.showImage ? (
          <ImageComponent showImage={this.state.showImage} startGame={this.props.startGame} />
        ) : (
          <div>
          </div>
        )}
        {/*  <div>*/}
        {/*    <video width="1920" height="1080" autoPlay playsInline onEnded={this.videoEnded.bind(this)}*/}
        {/*           id="samsung-video" controls>*/}
        {/*      <source src={VideoSamsung} type="video/mp4"></source>*/}
        {/*      Your browser does not support the video tag.*/}
        {/*    </video>*/}
        {/*  </div>*/}
      </div>
    );
  }
}
