import React, { Ref } from "react";

interface VideoContainerProps {
  gameStarted: boolean;
  videoRef: Ref<HTMLVideoElement>;
  canvasRef: Ref<HTMLCanvasElement>;
  canvasImageRef: Ref<HTMLCanvasElement>;
  textCanvas: Ref<HTMLCanvasElement>;
}

const VideoContainer = ({
                          gameStarted,
                          videoRef,
                          canvasRef,
                          canvasImageRef,
                          textCanvas
                        }: VideoContainerProps): JSX.Element => {
  return (
    <div className="video">
      <video
        ref={videoRef}
        id="video"
        playsInline
        style={{ display: "none" }}
      />
      <canvas
        ref={canvasRef}
        id="canvas"
        style={{ display: gameStarted ? "initial" : "none", width: "100vw", height: "100vh" }}
      />
      <canvas
        ref={canvasImageRef}
        id="canvas-image-ref"
        style={{
          display: gameStarted ? "initial" : "none",
          zIndex: 3,
          opacity: 0.8,
          width: "100vw",
          height: "100vh"
        }} />
      <canvas ref={textCanvas}
              id="text-canvas"
              style={{ display: gameStarted ? "initial" : "none", zIndex: 4, width: "100vw", height: "100vh" }} />
    </div>
  );
};

export default VideoContainer;
