import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

ReactDOM.render(
  // NOTE: This calls the App.tsx class twice, if you want to fix something comment the React.StrictMode out.
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
