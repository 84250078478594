import React from "react";
import background from "../Images/IntroScreen.png";

interface explanationTextInterface {
  showText: boolean;
  startGame: () => void;
}

export default class ExplanationTextComponent extends React.Component<explanationTextInterface, any> {

  constructor(props: any) {
    super(props);

    this.state = {
      showText: true
    };

    setTimeout(() => {
      this.props.startGame();
    }, 5000);
  }

  componentDidMount() {
    this.props.startGame();
  }

  render() {
    return (
      <div>
        {this.state.showText ? (
          <div className="explanation-text-wrapper">
            <div className="text-box">
              <h1>Speluitleg</h1>
              <span>
                1. Probeer jezelf door de vormen van <br /> de muren te bewegen.
                <br /> <br />
                2. Zorg dat je de juiste positie hebt aangenomen <br />op het moment dat de muur passeert.
               <br /> <br />
                3. Hou altijd je gezicht naar het scherm gericht.
              </span>
            </div>
          </div>
        ) : (
          <div></div>
        )
        }
      </div>
    );
  }
}