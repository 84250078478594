import React from "react";
import ExplanationTextComponent from "./ExplanationTextComponent";
import background from "../Images/Introscreen.png";
import VideoSamsung from "../Images/game-promo.mp4";

interface imageInterface {
  showImage: boolean;
  startGame: () => void;
}

export default class ImageComponent extends React.Component<imageInterface, any> {

  constructor(props: any) {
    super(props);

    this.state = {
      showImage: this.props.showImage
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ showImage: false });
    }, 5000);
  }

  render() {
    return (
      <div>
        {this.state.showImage ? (
          // <div className="explanation-information-bg-wrapper">
          //   <img className="image-component" src={background} />
          //
          // </div>
          <video width="1920" height="1080" autoPlay muted playsInline
                 id="intro-video">
            <source src={VideoSamsung} type="video/mp4"></source>
            Your browser does not support the video tag.
          </video>
        ) : (
          <ExplanationTextComponent showText={true} startGame={this.props.startGame} />
        )}
      </div>
    );
  }

}
