import { PoseNet } from "@tensorflow-models/posenet";
import { positions } from "../data/samplePoses";
import { poseSimilarity } from "posenet-similarity";
import { drawOverlay } from "./guiUtils";
import { counterTotal, minPoseConfidence } from "../constants";
import succesMusic from "../Images/success.mp3";
import failedMusic from "../Images/Game-over.mp3";
import {
  guiConfiguration,
  videoHeight,
  videoWidth
} from "../constants";

let level: number = 0;

export const detectPoseInRealTime = async (
  canvas: HTMLCanvasElement | null,
  canvasImageRef: HTMLCanvasElement | null,
  textCanvasRef: HTMLCanvasElement | null,
  video: HTMLVideoElement,
  net: PoseNet,
  increasePoints: (pointAmount: number) => void,
  setLevel: (level: number) => void,
  setCounter: (counter: number) => void,
  setGameState: (gameOver: boolean) => void
): Promise<void> => {
  const ctx = canvas?.getContext("2d");
  const ctxi = canvasImageRef?.getContext("2d");
  const textCanvas = textCanvasRef?.getContext("2d");
  if (!canvas || !ctx) return;
  if (!canvasImageRef || !ctxi) return;
  if (!textCanvas || !textCanvasRef) return;

  canvas.width = videoWidth;
  canvas.height = videoHeight;
  canvasImageRef.width = videoWidth;
  canvasImageRef.height = videoHeight;
  textCanvasRef.width = videoWidth;
  textCanvasRef.height = videoHeight;

  let counter = 0;
  let currPose = positions[level];
  const poseDetectionFrame = async () => {
    // const minPoseConfidence =
    //   +posenetConfiguration.singlePoseDetection.minPoseConfidence;
    // const minPartConfidence =
    //   +posenetConfiguration.singlePoseDetection.minPartConfidence;

    if (counter == 0) {
      await net.estimateSinglePose(video, {
        flipHorizontal: true
      });
    }

    if (counter == counterTotal) {
      //This retrieves the pose from the webcam
      const pose = await net.estimateSinglePose(video, {
        flipHorizontal: true
      });

      //Check the similarity with the given webcam feed and the keypoint from json file
      const weightedDist = poseSimilarity(currPose.pose, pose, {
        strategy: "cosineSimilarity"
      });
      if (weightedDist >= minPoseConfidence) {
        increasePoints(currPose.points);
        levelSuccess(textCanvas, currPose.points);
        playsucces();
      } else {
        levelFailed(textCanvas);
        playfailed();
      }
      counter = 0;
      level++;
      currPose = getNewPosition(ctxi);
      if (checkLevel()) {
        setGameState(checkLevel());
        level = 0;
        setLevel(0);
        return;
      }
      setLevel(level);
    }

    if (guiConfiguration.showVideo) {
      ctx.save();
      ctx.scale(-1, 1);
      ctx.translate(-videoWidth, 0);
      ctx.drawImage(video, 0, 0, videoWidth, videoHeight);
      ctx.restore();
    }

    const scale = counter / counterTotal;

    drawOverlay(
      currPose.pose.keypoints,
      0,
      ctxi,
      scale,
      "rgba(255,255,255,0.3)",
      20,
      currPose.image
    );
    // if (pose.score >= minPoseConfidence) {
    //   if (guiConfiguration.showPoints) {
    //     drawKeypoints(pose.keypoints, minPartConfidence, ctx);
    //   }
    //   if (guiConfiguration.showSkeleton) {
    //     drawSkeleton(
    //       pose.keypoints,
    //       minPartConfidence,
    //       ctx,
    //       lineWidth,
    //       skeletonWidth
    //     );
    //
    //     if (guiConfiguration.showBoundingBox) {
    //       drawBoundingBox(pose.keypoints, ctx);
    //     }
    //   }
    // }

    requestAnimationFrame(poseDetectionFrame);
    counter++;
    setCounter(counter);
  };
  poseDetectionFrame();
};

function getNewPosition(ctx: CanvasRenderingContext2D) {
  ctx.clearRect(0, 0, videoWidth, videoHeight);
  return positions[level];
}

function checkLevel() {
  return level == 10;
}

function levelFailed(ctx: CanvasRenderingContext2D) {
  setTimeout(() => {
    ctx.clearRect(0, 0, videoWidth, videoHeight);
  }, 500);
  ctx.font = "100px SamsungsBold";
  ctx.strokeStyle = "white";
  ctx.lineWidth = 1;
  ctx.strokeText("Wall Failed", (videoWidth / 4) * 1.46, videoHeight / 2);
  ctx.fillStyle = "red";
  ctx.fillText("Wall Failed", (videoWidth / 4) * 1.46, videoHeight / 2);
}

function playfailed() {
  new Audio(failedMusic).play();
}

function playsucces() {
  new Audio(succesMusic).play();
}

function levelSuccess(ctx: CanvasRenderingContext2D, points: number) {
  setTimeout(() => {
    ctx.clearRect(0, 0, videoWidth, videoHeight);
  }, 500);

  ctx.font = "100px SamsungsBold";
  ctx.strokeStyle = "white";
  ctx.lineWidth = 2;
  ctx.strokeText("Well Flipped", (videoWidth / 4) * 1.46, videoHeight / 2);
  ctx.fillStyle = "#0077C8";
  ctx.fillText("Well Flipped", (videoWidth / 4) * 1.46, videoHeight / 2);
  ctx.fillText("+ " + points, (videoWidth / 4) * 1.46, videoHeight / 2.2);
}
