import React, { useRef, useState } from "react";
import { load } from "@tensorflow-models/posenet";
import { posenetConfiguration as posenetConfig } from "./constants";
import { setupCamera } from "./utils/videoUtils";
import { detectPoseInRealTime } from "./utils/poseUtils";

import VideoContainer from "./components/VideoContainer";
import HUD from "./components/HUD";

import "./App.css";
import OverlayComponent from "./components/OverlayComponent";
import ExplanationComponent from "./components/ExplanationComponent";

const App = (): JSX.Element => {
  const [points, setPoints] = useState<number>(0);
  const [gameStarted, setGameStarted] = useState<boolean>(false);
  const [gameOver, setGameOver] = useState<boolean>(false);
  const [level, setLevel] = useState<number>(0);
  const [counter, setCounter] = useState<number>(0);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const canvasImageRef = useRef<HTMLCanvasElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const textCanvas = useRef<HTMLCanvasElement>(null);

  const startGame = async () => {
    setGameStarted(true);
    const video = await setupCamera(videoRef.current);
    const net = await load({
      architecture: posenetConfig.input.architecture,
      inputResolution: posenetConfig.input.inputResolution,
      outputStride: posenetConfig.input.outputStride,
      multiplier: posenetConfig.input.multiplier,
      quantBytes: posenetConfig.input.quantBytes
    });

    if (videoRef.current && video) {
      video.play();
      detectPoseInRealTime(canvasRef.current, canvasImageRef.current, textCanvas.current, video, net, (pointAmount) =>
          setPoints((point) => point + pointAmount),
        (level) => setLevel(level),
        (counter) => setCounter(counter),
        (gameOver) => setGameOver(gameOver)
      );
    }
  };

  const restartGame = () => {
    setPoints(0);
    setGameStarted(false);
    setGameOver(false);
  };

  return (
    <div className="App">
      {!gameStarted ? (
        <ExplanationComponent startGame={startGame} startVideo={true} />
        // <IntroContainer startGame={startGame} />
      ) : (
        <HUD points={points} counter={counter} level={level} gameOver={gameOver}
             restartGame={restartGame} />
      )}
      <VideoContainer
        gameStarted={gameStarted}
        canvasRef={canvasRef}
        canvasImageRef={canvasImageRef}
        textCanvas={textCanvas}
        videoRef={videoRef}
      />
      <OverlayComponent gameStarted={gameStarted} />
    </div>
  );
};

export default App;
