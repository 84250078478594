import React from "react";

interface scoreboardInterface {
  restartGame: () => void;
}

export default class ScoreboardComponent extends React.Component<scoreboardInterface, any> {

  constructor(props: any) {
    super(props);

    this.state = {
      scores: []
    };

    setTimeout(() => {
      this.props.restartGame();
    }, 30000);
  }

  componentDidMount() {
    setTimeout(() => {
      let url = "";
      if (process.env.NODE_ENV === "development") {
        url = process.env.REACT_APP_DEV_SERVER_URL + "";
      } else if (process.env.NODE_ENV === "production") {
        url = process.env.REACT_APP_PROD_SERVER_URL + "";
      }
      fetch(url + "scores/highest", {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      }).then((response) => response.json())
        .then((response) => {
          this.setState({ scores: response.entity });
        }).catch((err) => {
        console.log(err);
      });
    }, 200);
  }

  render() {
    return (
      <div className="scoreboard-wrapper">
        <table className="scoreboard">
          <th>
            Positie
          </th>
          <th>
            Score
          </th>
          <th>
            Tijdstip
          </th>
          <tbody>
          {this.state.scores.map((object: any, i: number) => {
            return <tr key={i}>
              <td>{object[3]}</td>
              <td className="white-borders"> {object[2]} </td>
              <td> {new Date(object[1]).toLocaleDateString()} </td>
            </tr>;
          })}
          </tbody>
        </table>
      </div>
    );
  }

}
