import React, { useState } from "react";
import ScoreboardComponent from "./ScoreboardComponent";
import { counterTotal } from "../constants";

interface HUDprops {
  points: number;
  counter: number;
  level: number;
  gameOver: boolean;
  restartGame: () => void;
}

export default class HUD extends React.Component<HUDprops, any> {

  constructor(props: any) {
    super(props);

    this.state = {
      myScore: {},
      position: 0
    };
  }

  componentDidUpdate(prevProps: Readonly<HUDprops>, prevState: Readonly<any>, snapshot?: any) {
    if (!prevProps.gameOver && this.props.gameOver) {
      let url = "";
      if (process.env.NODE_ENV === "development") {
        url = process.env.REACT_APP_DEV_SERVER_URL + "";
      } else if (process.env.NODE_ENV === "production") {
        url = process.env.REACT_APP_PROD_SERVER_URL + "";
      }
      fetch(url + "scores", {
        method: "POST",
        body: JSON.stringify({ total: this.props.points }),
        headers: {
          "Content-Type": "application/json"
        }
      }).then((response) => response.json())
        .then((response) => {
          this.setState({ position: response.entity.position });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  render() {
    return (
      <div className="HUD">
        {this.props.gameOver ? (
          <>
            <div className="game-over">
              <ScoreboardComponent restartGame={this.props.restartGame}></ScoreboardComponent>
              <p>U heeft positie {this.state.position} behaald met {this.props.points} punten</p>
            </div>
          </>
        ) : (
          <>
            <div className="flex-container">
              <div className="flex-cc">
                <div className="item1">Score</div>
                <div className="box-item1">
                  <span className="item1-score">{`${this.props.points}`}</span>
                </div>
              </div>

              <div className="flex-cc2">
                <div className="item2">Time to Flip</div>
                <div className="progress-bar">
                  <div className="filler" style={{ width: `${(this.props.counter / counterTotal) * 100}%` }}></div>
                </div>
              </div>

              <div className="flex-cc">
                <div className="item3">Level</div>
                <div className="box-item3">
                  <span className="item3-score"> {this.props.level + 1} / 10</span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}
