import React from "react";
import BackgroundMusic from "../Images/music-Loop.mp3";

interface overlayComponent {
  gameStarted: boolean;
}

export default class OverlayComponent extends React.Component<overlayComponent,
  unknown> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return this.props.gameStarted ? (

      <div className="bg-image-wrapper">
        <div>
          <img className="background-image" />
          <audio autoPlay loop>
            <source src={BackgroundMusic} type="audio/mpeg">
            </source>
          </audio>
        </div>
      </div>
    ) : (
      <div></div>
    );
  }
}
