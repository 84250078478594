import {
  getAdjacentKeyPoints,
  getBoundingBox,
  Keypoint
} from "@tensorflow-models/posenet";
import { defaultHeight, defaultWidth, guiConfiguration, speed } from "../constants";
import { videoHeight, videoWidth } from "../constants";
import { drawPoint, drawSegment, toTuple } from "./canvasUtils";
import { aquaColor, boundingBoxColor, skeletonWidth } from "../constants";
import wall1 from "../Images/Muur-01.png";
import wall2 from "../Images/Muur-02.png";
import wall3 from "../Images/Muur-03.png";
import wall4 from "../Images/Muur-04.png";
import wall5 from "../Images/Muur-05.png";
import wall6 from "../Images/Muur-06.png";
import wall7 from "../Images/Muur-07.png";
import wall8 from "../Images/Muur-08.png";
import wall9 from "../Images/Muur-09.png";
import wall10 from "../Images/Muur-10.png";

let width = defaultWidth;
let height = defaultHeight;
let startPosition = 0;

export const drawOverlay = (
  keypoints: Keypoint[],
  minPartConfidence: number,
  ctx: CanvasRenderingContext2D,
  scale: number,
  background: string | undefined = undefined,
  thickness = 3,
  imageName: string
): void => {

  if (scale < 0.1) {
    width = defaultWidth;
    height = defaultHeight;
  }

  ctx.clearRect(0, 0, videoWidth, videoHeight);
  ctx.save();
  const bboxMidY =
    videoHeight / 3.9;
  const bboxMidX =
    videoWidth / 3;

  // const videoMidY = videoHeight / 100 * 70;
  // const videoMidX = videoWidth / 2;

  // const deltaY = videoMidY - bboxMidY;
  // const deltaX = videoMidX - bboxMidX;

  // scale down
  // ctx.scale(1, 1);

  if (background) {
    const image = new Image();
    switch (imageName) {
      case "wall1":
        image.src = wall1;
        break;
      case "wall2":
        image.src = wall2;
        break;
      case "wall3":
        image.src = wall3;
        break;
      case "wall4":
        image.src = wall4;
        break;
      case "wall5":
        image.src = wall5;
        break;
      case "wall6":
        image.src = wall6;
        break;
      case "wall7":
        image.src = wall7;
        break;
      case "wall8":
        image.src = wall8;
        break;
      case "wall9":
        image.src = wall9;
        break;
      case "wall10":
        image.src = wall10;
        break;
    }
    ctx.save();
    // ctx.translate(videoMidX / scale, videoMidY / scale);
    // ctx.translate(-videoMidX, -videoMidY);
    ctx.fillStyle = background;
    startPosition = bboxMidX - ((defaultWidth * speed) * (scale * 125));
    ctx.drawImage(image, startPosition, bboxMidY, width, height);
    width += defaultWidth * speed;
    height += defaultHeight * speed;
    ctx.restore();
  }

  // move skeleton back to bbox
  ctx.translate((1 / scale - 1) * bboxMidX, (1 / scale - 1) * bboxMidY);

  // move skeleton to middle of canvas
  // ctx.translate(deltaX / scale, deltaY / scale);
  if (guiConfiguration.showPoints) {
    drawKeypoints(keypoints, minPartConfidence, ctx, thickness);
  }
  if (guiConfiguration.showSkeleton) {
    drawSkeleton(keypoints, minPartConfidence, ctx, thickness, skeletonWidth);
  }
  if (guiConfiguration.showBoundingBox) {
    drawBoundingBox(keypoints, ctx);
  }
  ctx.restore();
};

// Draws a pose skeleton by looking up all adjacent keypoints/joints
export const drawSkeleton = (
  keypoints: Keypoint[],
  minConfidence: number,
  ctx: CanvasRenderingContext2D,
  thickness: number,
  scale: 1
): void => {
  const adjacentKeyPoints = getAdjacentKeyPoints(keypoints, minConfidence);

  adjacentKeyPoints.map((keypoints) => {
    drawSegment(
      toTuple(keypoints[0].position),
      toTuple(keypoints[1].position),
      aquaColor,
      scale,
      ctx,
      thickness
    );
  });
};

// Draw pose keypoints onto a canvas
export const drawKeypoints = (
  keypoints: Keypoint[],
  minConfidence: number,
  ctx: CanvasRenderingContext2D,
  radius = 3,
  scale = 1
): void => {
  keypoints.map((keypoint) => {
    if (keypoint.score > minConfidence) {
      const { y, x } = keypoint.position;
      drawPoint(ctx, y * scale, x * scale, radius, aquaColor);
    }
  });
};

// Draw the bounding box of a pose. For example, for a whole person standing in an image, the bounding box will begin at the nose and extend to one of ankles
export const drawBoundingBox = (
  keypoints: Keypoint[],
  ctx: CanvasRenderingContext2D
): void => {
  const boundingBox = getBoundingBox(keypoints);
  ctx.rect(
    boundingBox.minX,
    boundingBox.minY,
    boundingBox.maxX - boundingBox.minX,
    boundingBox.maxY - boundingBox.minY
  );
  ctx.strokeStyle = boundingBoxColor;
  ctx.stroke();
};
